import './index.scss';
import applyPolyfills from '@benefex/react/app/applyPolyfills';

async function enableMocking() {
  if (process.env.NODE_ENV !== 'development' || !process.env.REACT_APP_ENABLE_MOCKING) {
    return;
  }

  const { worker } = await import('./mocks/browser');

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start();
}

enableMocking().then(() =>
  applyPolyfills().then(() => import(/* webpackChunkName: "appMain" */ './main')),
);
